import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'

import { LinkCustom, Seo } from '../components/index'

const schemaDavidDias = {
  '@context': 'https://schema.org',
  '@type': 'Person',
  name: 'David Dias',
  url: 'http://thedaviddias.dev',
  sameAs: [
    'http://www.facebook.com/thedaviddias',
    'http://www.twitter.com/thedaviddias',
    'http://instagram.com/thedaviddias',
    'http://www.linkedin.com/in/thedaviddias',
  ],
}

const AboutDavidDias = () => {
  return (
    <>
      <Seo
        title="About David Dias"
        description="I'm David Dias and I'm passionate about people and web development. Let me share with you a little bit of my story and motivates me to host World Web Stories."
        pathname="about-david-dias/"
        isLanding
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaDavidDias)}</script>
      </Helmet>

      <div className="bg-white">
        <section className="max-w-6xl mx-auto px-2 pt-44 sm:px-4 lg:px-8">
          <div className="sm:pb-10 md:pb-10">
            <div className="grid grid-cols-1 gap-y-10 md:grid-cols-3 md:gap-20">
              <div>
                <h1 className="font-bold text-gray-900 pb-10">
                  Hello, I&apos;m <p className="text-5xl md:text-6xl">David Dias</p>
                </h1>
                <p className="text-xl text-gray-700 pb-10">
                  Software Engineer passionate about people and host of the podcast World Web
                  Stories
                </p>
                <div>
                  <span>Find me on:</span>
                  <ul className="flex">
                    <li>
                      <LinkCustom
                        href="https://thedaviddias.dev"
                        className="text-gray-400 hover:text-gray-300 p-2 block"
                        title="Access my blog"
                      >
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-7 w-7"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </LinkCustom>
                    </li>
                    <li>
                      <LinkCustom
                        href="https://facebook.com/thedaviddias"
                        className="text-gray-400 hover:text-gray-300 p-2 block"
                        title="Access my Facebook profile"
                      >
                        <span className="sr-only">Facebook</span>
                        <svg
                          className="h-7 w-7"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </LinkCustom>
                    </li>
                    <li>
                      <LinkCustom
                        href="https://twitter.com/thedaviddias"
                        className="text-gray-400 hover:text-gray-300 p-2 block"
                        title="Access my Twitter account"
                      >
                        <span className="sr-only">Twitter</span>
                        <svg
                          className="h-7 w-7"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </LinkCustom>
                    </li>
                    <li>
                      <LinkCustom
                        href="https://instagram.com/thedaviddias"
                        className="text-gray-400 hover:text-gray-300 p-2 block"
                        title="Access the Instagram account of David Dias"
                      >
                        <span className="sr-only">Instagram</span>
                        <svg
                          className="h-7 w-7"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </LinkCustom>
                    </li>
                    <li>
                      <LinkCustom
                        href="https://dev.to/thedaviddias"
                        className="text-gray-400 hover:text-gray-300 p-2 block"
                        title="Access my Instagram account"
                      >
                        <span className="sr-only">Dev.to</span>

                        <svg
                          className="h-7 w-7"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M7.42 10.05c-.18-.16-.46-.23-.84-.23H6l.02 2.44.04 2.45.56-.02c.41 0 .63-.07.83-.26.24-.24.26-.36.26-2.2 0-1.91-.02-1.96-.29-2.18zM0 4.94v14.12h24V4.94H0zM8.56 15.3c-.44.58-1.06.77-2.53.77H4.71V8.53h1.4c1.67 0 2.16.18 2.6.9.27.43.29.6.32 2.57.05 2.23-.02 2.73-.47 3.3zm5.09-5.47h-2.47v1.77h1.52v1.28l-.72.04-.75.03v1.77l1.22.03 1.2.04v1.28h-1.6c-1.53 0-1.6-.01-1.87-.3l-.3-.28v-3.16c0-3.02.01-3.18.25-3.48.23-.31.25-.31 1.88-.31h1.64v1.3zm4.68 5.45c-.17.43-.64.79-1 .79-.18 0-.45-.15-.67-.39-.32-.32-.45-.63-.82-2.08l-.9-3.39-.45-1.67h.76c.4 0 .75.02.75.05 0 .06 1.16 4.54 1.26 4.83.04.15.32-.7.73-2.3l.66-2.52.74-.04c.4-.02.73 0 .73.04 0 .14-1.67 6.38-1.8 6.68z" />
                        </svg>
                      </LinkCustom>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="sm:pl-40 col-span-2">
                <div className="font-serif text-xl pb-10">
                  <StaticImage
                    src="../../static/assets/david-dias/david-dias-intro.png"
                    alt="David Dias"
                    width={300}
                    loading="eager"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="max-w-6xl mx-auto px-2 sm:px-4 lg:px-8 pt-20">
        <div className="grid grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-20">
          <div>
            <h2 className="text-4xl pb-10">Passionate about people and technology</h2>
            <p className="text-lg pb-4">
              I first started using my voice professionally in 2006, in a local radio in Tours
              (France). Listening to our own voice is weird, it feels weird at first. But it&apos;s
              when people start get inspired by your words than your start realizing they are not
              your words anymore.
            </p>
            <p className="text-lg pb-10">
              I have always been passionate about people around the world, I lived in 4 different
              countries (France{' '}
              <span role="img" aria-label="France flag">
                🇫🇷
              </span>
              , Brazil{' '}
              <span role="img" aria-label="Brazil flag">
                🇧🇷
              </span>
              , Mauritius{' '}
              <span role="img" aria-label="Mauritius flag">
                🇲🇺
              </span>
              , Canada{' '}
              <span role="img" aria-label="Canada flag">
                🇨🇦
              </span>
              ) and learned so much from so many people. In 2021, I decided to travel around the
              world, but from behind my desk. I decided to redefine an old podcast project: World
              Web Stories.
            </p>
            <p className="text-lg pb-4">
              The new podcast World Web Stories aspires to be more than just a podcast, it&apos;s a
              way to listen to life stories from people and from places you may never know or visit.
              And because I love web development and Internet in general, why not meet with people
              that share the same passion?
            </p>
            <p className="text-lg pb-4">
              I invite you to join me for this new journey around the world!
            </p>
          </div>
          <div>
            <StaticImage
              // className="w-16"
              width={500}
              src="../../static/assets/david-dias/about-david-dias-patchwork.png"
              alt="Multiple photos of David Dias in different events he participated or created"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutDavidDias
